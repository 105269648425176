import { Address, idName } from './patient-registration.model';

export interface User {
  authorizeToWriteMedOrder: 0 | 1;
  canManageHourReports: 0 | 1
  available: 0 | 1;
  firstName: string;
  lastName: string;
  fullName: string;
  email: string;
  id: number;
  canManageUsers: 1 | 0;
  chatStatus: string;
  dea: string;
  canAddPatient: 1 | 0;
  defaultClinicId: number;
  degree: string;
  googleCalendarKey: string;
  limitedPatientAccess: 0 | 1;
  middleName: string;
  npi: string;
  pages: string[];
  userType: string;
  dob: string;
  dateCreated: string;
}

export interface Doctors {
  available: 0 | 1;
  id: number;
  intern: 0 | 1;
  hideInReferralList: 0 | 1;
  name: string;
  officePhone: string;
  officePhoneExt: string;
  populationGroups: string;
  primaryDoctor: string;
  specialties: string;
  internies?: Doctors[];
}

export class TokenExp {
  constructor(private _token: string, private _tokenExpDate: Date) {}

  get token() {
    if (!this._tokenExpDate || new Date() > this._tokenExpDate) {
      return null;
    }
    return this._token;
  }
}

export interface userInfo {
  dea: string;
  degree: string;
  email: string;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  middleName: string;
  npi: string;
}

export interface ProviderDetails {
  address: Address;
  dea: string;
  degree: string;
  email: string;
  name: string;
  npi: string;
  phone: string;
}

export interface UsersList {
  associatedUsers: idName[];
  authorizeToWriteMedOrder: 1 | 0;
  canHoldPatient: 1 | 0;
  canAddPatient: 1 | 0;
  readOnly: 1 | 0;
  canAccessAllClaims: 0 | 1;
  createdBy: string;
  dateEntered: string;
  dea: string;
  deaExpiryDate: string;
  degree: string;
  disabled: 0 | 1;
  dob: string;
  electronicSignatureCode: string;
  limitedPatientAccess: 0 | 1;
  canDownloadSessions: 0 | 1;
  canDownloadMultipleSessions: 0 | 1;
  canManageHourReports: 0 | 1;
  "2FA"?: string;
  officeEmail: string;
  privateEmail: string;
  fax: string;
  firstName: string;
  fullName: string;
  gender: 'MALE' | 'FEMALE';
  id: number;
  initial: string;
  lastName: string;
  middleName: string;
  address?: Address;
  phone: Phone;
  ssn: number;
  clinician?: 0 | 1;
  npi1?: string;
  npi2?: string;
  intern?: 0 | 1;
  primaryDoctor: any;
  primaryDoctorName: string;
  googleCalendarKey?: string;
  stateLiceses: { state: State }[];
  personClasses?: { personClass: idName }[];
  pages?: idName[];
  userActionTypes?: idName[];
  associatedClinics?: idName[];
  defaultClinic?: idName;
  scope: string;
  suffix: string;
  type: string;
  internies?: UsersList[];
}

export interface EditeUser {
  firstName: string;
  middleName?: string;
  lastName: string;
  suffix?: string;
  initial: string;
  gender: 'MALE' | 'FEMALE';
  dob: string;
  ssn?: number;
  phone?: Phone;
  email?: string;
  fax?: string;
  electronicSignatureCode: string;
  authorizeToWriteMedOrder: 0 | 1;
  degree?: string;
  dea?: string;
  deaExpiryDate?: string;
  type?: string;
  googleCalendarKey?: string;
  stateLicenses?: StateLicenses;
  personClasses?: { classId: number }[];
  associatedUsers?: { userId: number }[];
  associatedClinics?: { clinicId: number }[];
}

export interface AddUserReqBody extends EditeUser {
  accessCode: string;
  verifyCode: string;
}

export interface ChangeUserVerifyCodeReqBody {
  userId: number;
  newVerifyCode: string;
  confirmNewVerifyCode: string;
}

export interface ChangeUserAccessCodeReqBody {
  userId: number;
  newAccessCode: string;
  confirmNewAccessCode: string;
  newVerifyCode: string;
  confirmNewVerifyCode: string;
}

export interface StateLicenses {
  stateId;
  licenseNumebr;
  expiryDate;
}

export interface State {
  expiryDate: string;
  id: number;
  licenseNumber: string;
  name: string;
  licenseType: idName;
  otherType: string;
  certificationBoard: idName;
  otherCertificationBoard: string;
}

export interface Phone {
  home?: number;
  mobile?: number;
  office?: number;
  officeExtension?: string;
  emergencyContactNumber?: number;
  emergencyContactName?: string;
  emergencyContactRelation?: string;
}

export interface PersonClasses extends idName {
  providerType: string;
  specialization: string;
}

export interface UserActionLogsReqBody {
  count: number;
  direction: 0 | 1;
  from?: number | '';
  patientId?: number | '';
  userId?: number | '';
  actions?: { id: number }[] | '';
}

export interface UserActionLogs {
  items: UserActionLogsItems[];
  next: 0 | 1;
  previous: 0 | 1;
}

export interface UserActionLogsItems {
  action: string;
  additionalInfo: any;
  dateTime: string;
  id: number;
  patient: string;
  user: string;
}
export interface EditUserProfile{

  firstName:string,
  lastName: string,
  middleName: string,
  gender: string,
  twoFA:string,
  dob: string,
  phone: {
      mobile: number,
      home: number,
      office: number,
      officeExtension: number,
      emergencyContactNumber: number,
      emergencyContactName: string,
      emergencyContactRelation: string
  },
  officeEmail: string,
  address: {
      line1: string,
      line2: string,
      line3: string,
      city: string,
      state: {
          id: number
      },
      zip: number
  }
}
